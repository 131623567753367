import get from 'lodash/get';
import classnames from 'classnames';
import emitter from '@/utils/emitter';
import {
  EMITTER_CHALLENGE_IFRAME,
  ENFORCEMENT_URL,
  ENFORCEMENT_FRAME_TITLE,
  DEFAULT_MODE,
} from '@/constants';
import styles from '@/api/components/EnforcementFrame/EnforcementFrame.scss';

const state = {
  show: false,
  overrideShowDelay: undefined,
  isActive: undefined,
  appElement: undefined,
  showDelay: 0,
  mode: undefined,
  timer: null,
};

const defaultWidth = '302px';
const defaultHeight = '290px';

let enforcementElement = null;
let iframeWidth = defaultWidth;
let iframeHeight = defaultHeight;

function getIframeElement(appElement) {
  if (get(appElement, 'childNodes[0]')) {
    return appElement.childNodes[0];
  }
  return null;
}

function updateClassName(appElement, iframeClass) {
  const element = getIframeElement(appElement);
  if (!element) {
    return;
  }

  element.setAttribute('class', iframeClass);

  let width = 0;
  let height = 0;
  if (iframeClass.indexOf('show') > -1) {
    width = iframeWidth;
    height = iframeHeight;
  }
  element.style.width = width;
  element.style.height = height;
}

const onChallengeIFrameReady = ({ width, height }) => {
  if (enforcementElement) {
    if (width && width !== enforcementElement.style.width) {
      enforcementElement.style.width = width;
      iframeWidth = width;
    }
    if (height && height !== enforcementElement.style.height) {
      enforcementElement.style.height = height;
      iframeHeight = height;
    }

    if (document.activeElement !== enforcementElement) {
      enforcementElement.focus();
    }
  }
};

emitter.on(EMITTER_CHALLENGE_IFRAME, onChallengeIFrameReady);

const EnforcementFrame = ({
  appElement,
  config,
  isActive,
  overrideShowDelay,
}) => {
  const showDelay = overrideShowDelay
    ? 0
    : get(config, 'settings.lightbox.showDelay');

  state.appElement = appElement;
  state.showDelay = showDelay;
  state.mode = get(config, 'mode');

  if (
    overrideShowDelay !== state.overrideShowDelay ||
    isActive !== state.isActive
  ) {
    state.isActive = isActive;
    state.overrideShowDelay = overrideShowDelay;
    if (state.timer) {
      state.show = false;
      clearTimeout(state.timer);
    }
    state.timer = setTimeout(() => {
      if (state.isActive) {
        state.show = true;

        const newIframeClass = classnames(styles.container, {
          show: true,
          active: true,
          [state.mode || DEFAULT_MODE]: true,
        });
        updateClassName(state.appElement, newIframeClass);
      }
    }, state.showDelay);
  }

  const iframeClass = classnames(styles.container, {
    show: !!state.show,
    active: !!state.isActive,
    [state.mode || DEFAULT_MODE]: true,
  });
  if (get(appElement, 'children', []).length < 1) {
    const enforcementIframe = document.createElement('iframe');
    enforcementIframe.setAttribute('src', ENFORCEMENT_URL);
    enforcementIframe.setAttribute('class', iframeClass);
    enforcementIframe.setAttribute('title', ENFORCEMENT_FRAME_TITLE);
    enforcementIframe.setAttribute('data-e2e', 'enforcement-frame');
    const stylesToApply = get(config, 'theme.container.style', {});
    // eslint-disable-next-line array-callback-return
    Object.keys(stylesToApply).map((key) => {
      // for IE these need to be set individually
      enforcementIframe.style[key] = stylesToApply[key];
    });
    appElement.appendChild(enforcementIframe);
    enforcementElement = enforcementIframe;
  } else if (get(appElement, 'childNodes[0].className') !== iframeClass) {
    updateClassName(appElement, iframeClass);
  }
};

export default EnforcementFrame;
