/* eslint-disable consistent-return */

import '@/utils/polyfills';
import 'element-closest';
import cloneDeep from 'lodash/cloneDeep';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import invoke from 'lodash/invoke';
import { size, intersection, isFunction } from '@/utils/helpers';
import emitter from '@/utils/emitter';
import debug from '@/utils/debug/';
import getHook from '@/utils/getHookFnFromElement';
import {
  API_SCRIPT_TAG_SELECTOR,
  ATTRIBUTE_CONTAINER_SELECTOR,
  ATTRIBUTE_EVENT_LIMIT_REACHED,
  ATTRIBUTE_EVENT_COMPLETED,
  ATTRIBUTE_EVENT_HIDE,
  ATTRIBUTE_EVENT_READY,
  ATTRIBUTE_EVENT_READY_INLINE,
  ATTRIBUTE_EVENT_RESET,
  ATTRIBUTE_EVENT_SHOW,
  ATTRIBUTE_EVENT_SHOWN,
  ATTRIBUTE_EVENT_SUPPRESS,
  CHALLENGE_API_URL,
  DEFAULT_MODE,
  ESCAPE_KEY_CODE,
  EMITTER_CHALLENGE_COMPLETED,
  EMITTER_CHALLENGE_LOADED,
  EMITTER_CHALLENGE_SHOWN,
  EMITTER_CHALLENGE_SUPPRESSED,
  EMITTER_CHALLENGE_RESET,
  EMITTER_CONFIG_REQUEST,
  EMITTER_CONFIG,
  EMITTER_ENFORCEMENT_LOADED,
  EMITTER_ENFORCEMENT_READY,
  EMITTER_HIDE_ENFORCEMENT,
  EMITTER_SHOW_ENFORCEMENT,
  ENFORCEMENT_HIDE_TRANSITION_MS,
  ENFORCEMENT_FRAME_TITLE,
  INLINE_ELEMENT_CHECK_FREQ,
  INLINE_MODE,
  PUBLIC_KEY,
  TARGET_ELEMENT_QUERY_SELECTOR,
  EMITTER_CHALLENGE_FRAME_READY,
  PACKAGE_VERSION,
  EMITTER_GAME_NUMBER_LIMIT_REACHED,
} from '@/constants';
import EnforcementFrame from '@/api/components/EnforcementFrame/EnforcementFrame';

emitter.context = 'api';

const log = debug('api:api');

const HOOK_ON_COMPLETED = 'onCompleted';
const HOOK_ON_HIDE = 'onHide';
const HOOK_ON_READY = 'onReady';
const HOOK_ON_RESET = 'onReset';
const HOOK_ON_SHOW = 'onShow';
const HOOK_ON_SHOWN = 'onShown';
const HOOK_ON_SUPPRESS = 'onSuppress';
const HOOK_ON_REACH_FAIL_LIMIT = 'onFailed';

const ATTRIBUTE_HOOKS = {
  [ATTRIBUTE_EVENT_COMPLETED]: HOOK_ON_COMPLETED,
  [ATTRIBUTE_EVENT_HIDE]: HOOK_ON_HIDE,
  [ATTRIBUTE_EVENT_READY]: HOOK_ON_READY,
  [ATTRIBUTE_EVENT_READY_INLINE]: HOOK_ON_READY,
  [ATTRIBUTE_EVENT_RESET]: HOOK_ON_RESET,
  [ATTRIBUTE_EVENT_SHOW]: HOOK_ON_SHOW,
  [ATTRIBUTE_EVENT_SHOWN]: HOOK_ON_SHOWN,
  [ATTRIBUTE_EVENT_SUPPRESS]: HOOK_ON_SUPPRESS,
  [ATTRIBUTE_EVENT_LIMIT_REACHED]: HOOK_ON_REACH_FAIL_LIMIT,
};

let state = {};
let inlineEmittedReady = false;

class ArkHookEvent {
  constructor({ completed, token, suppressed } = {}) {
    this.completed = !!completed;
    this.token = token;
    this.suppressed = !!suppressed;
  }
}

const buildAppElement = () => {
  const el = document.createElement('div');
  el.setAttribute('aria-hidden', true);
  return el;
};

const getInitialState = (initialState = {}) => ({
  appEl: buildAppElement(),
  bodyElement: document.querySelector('body'),
  savedActiveElement: null,
  modifiedSiblings: [],
  challengeLoadedEvents: [],
  containerEl: null,
  elements: () => document.querySelectorAll(state.config.selector),
  enforcementLoaded: false,
  enforcementReady: false,
  getPublicKeyTimeout: null,
  isActive: false,
  suppressed: false,
  isResettingChallenge: false,
  ...initialState,
  config: {
    clientData: {},
    selector: TARGET_ELEMENT_QUERY_SELECTOR,
    settings: {},
    accessibilitySettings: {
      lockFocusToModal: false,
    },
    ...initialState.config,
  },
  events: {
    ...initialState.events,
  },
});

const resetEnforcement = () => {
  const { appEl, bodyElement, containerEl, events } = state;

  log('resetting the enforcement');
  state.isActive = false;
  state.completed = false;
  inlineEmittedReady = false;

  // prettier-ignore
  if (state.timeoutCheckInlineActive) clearTimeout(state.timeoutCheckInlineActive);
  if (state.timeoutSetupInline) clearTimeout(state.timeoutSetupInline);

  render(); // eslint-disable-line no-use-before-define

  if (bodyElement && events) {
    bodyElement.removeEventListener('click', events.bodyClicked);
  }

  if (containerEl && appEl && containerEl.contains(appEl)) {
    // This timeout is to allow for any remaining requests to finish before removing the iframe
    // we've had cases where the iframe was removed before the analytics onloaded request was finished
    // which resulted in incomplete metrics
    setTimeout(() => {
      containerEl.removeChild(appEl);
    }, 5000);
  }

  setup(cloneDeep(state)); // eslint-disable-line no-use-before-define
  setupMode(get(state, 'config.mode', DEFAULT_MODE)); // eslint-disable-line no-use-before-define
};

const onResetChallenge = () => {
  state.isActive = false;
  state.enforcementReady = false;
  state.challengeLoadedEvents = [EMITTER_CHALLENGE_FRAME_READY];
  state.isResettingChallenge = true;
};

const render = (props = {}) => {
  try {
    EnforcementFrame({
      appElement: state.appEl,
      config: state.config,
      isActive: state.isActive,
      overrideShowDelay: props.overrideShowDelay,
    });
  } catch (err) {
    log('Failed to render', err);
  }
};

const getConfig = () => cloneDeep(state.config);

const setConfig = (config = {}) => {
  // Ensure mode never not a supported option
  let mode = config.mode || get(state, 'config.mode');
  switch (mode) {
    case INLINE_MODE:
      break;
    default:
      mode = DEFAULT_MODE;
  }

  state.config = {
    ...state.config,
    ...config,
    ...{ mode },
    ...{ settings: { ...state.config.settings, ...config.settings } },
    publicKey: PUBLIC_KEY,
  };
  emitter.emit(EMITTER_CONFIG, state.config);
  render();
  return state.config;
};

const invokeHook = (hookName, ...args) => {
  forEach(state.elements(), (element) => getHook(element, hookName)(...args));
  invoke(state.events, ATTRIBUTE_HOOKS[hookName], ...args);
};

// ariaHideSiblings adds aria-hidden attributes to all siblings of
// the CAPI div on the body. This prevents screen readers from leaving
// the modal and entering areas that are supposed to be inaccessible.
// Elements changed here are kept in state so we know the exact elements to
// revert, just in case something changes in the meantime when the modal is up.
const ariaHideSiblings = () => {
  const { children } = state.bodyElement;
  state.modifiedSiblings = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0, len = children.length; i < len; i++) {
    const c = children.item(i);
    const ariaHiddenState = c.getAttribute('aria-hidden');
    if (c === state.appEl || ariaHiddenState === 'true') {
      continue; // eslint-disable-line no-continue
    }

    state.modifiedSiblings.push({ elem: c, ariaHiddenState });
    c.setAttribute('aria-hidden', true);
  }
};

// ariaShowSiblings reverts the addition of aria-hidden made by ariaHideSiblings.
const ariaShowSiblings = () => {
  const children = state.modifiedSiblings;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, len = children.length; i < len; i++) {
    const { elem, ariaHiddenState } = children[i];
    if (elem === state.appEl) {
      continue; // eslint-disable-line no-continue
    }

    if (ariaHiddenState === null) {
      elem.removeAttribute('aria-hidden');
    } else {
      elem.setAttribute('aria-hidden', ariaHiddenState);
    }
  }
};

// ariaSetAppElHidden modifies the aria-hidden value on the app element
const ariaSetAppElHidden = (value) => {
  state.appEl.setAttribute('aria-hidden', value);
};

// Save the active focus so we can restore it when the modal is closed
const saveFocusState = () => {
  state.savedActiveElement = document.activeElement;
};

// Restore the focus state after the modal is closed
const restoreFocusState = () => {
  if (!state.savedActiveElement) {
    return;
  }
  state.savedActiveElement.focus();
  state.savedActiveElement = null;
};

const onReachFailLimit = (ctx = {}) => {
  state.token = ctx.token;
  invokeHook(ATTRIBUTE_EVENT_LIMIT_REACHED, new ArkHookEvent(state), ctx);
};

const onChallengeCompleted = (ctx = {}) => {
  state.completed = true;
  state.token = ctx.token;
  invokeHook(ATTRIBUTE_EVENT_COMPLETED, new ArkHookEvent(state), ctx);
};

const onChallengeLoaded = ({ event } = {}) => {
  state.challengeLoadedEvents.push(event);
  const mode = get(state, 'config.mode');

  let eventsToWaitFor = [EMITTER_CHALLENGE_LOADED];
  if (mode !== INLINE_MODE) {
    eventsToWaitFor = [
      ...eventsToWaitFor,
      EMITTER_ENFORCEMENT_LOADED,
      EMITTER_CHALLENGE_FRAME_READY,
    ];
  }

  const { challengeLoadedEvents } = state;
  const numEventsToWaitFor = size(eventsToWaitFor);
  const numEventsTriggered = size(
    intersection(challengeLoadedEvents, eventsToWaitFor)
  );
  const isReady = numEventsTriggered === numEventsToWaitFor;
  const hook =
    state.previousState && state.previousState.token
      ? ATTRIBUTE_EVENT_RESET
      : ATTRIBUTE_EVENT_READY;

  if (isReady) {
    state.enforcementReady = true;
    emitter.emit(EMITTER_ENFORCEMENT_READY);
    if (!(hook === ATTRIBUTE_EVENT_READY && inlineEmittedReady)) {
      invokeHook(hook, new ArkHookEvent(state));
    }

    if (mode === INLINE_MODE) {
      showEnforcement(); // eslint-disable-line no-use-before-define
    }
  }
};

const onChallengeSuppressed = () => {
  log('onChallengeSuppressed');
  state.isActive = false;
  state.suppressed = true;
  render();
  invokeHook(ATTRIBUTE_EVENT_SUPPRESS, new ArkHookEvent(state));
};

const onChallengeShown = () => {
  log('onChallengeShown');
  state.isActive = true;
  render({ overrideShowDelay: true });
  invokeHook(ATTRIBUTE_EVENT_SHOWN, new ArkHookEvent(state));
};

const onEnforcementLoaded = (config) => {
  state.enforcementLoaded = true;
  setConfig(config);
  onChallengeLoaded({ event: EMITTER_ENFORCEMENT_LOADED });
  render();
};

const onHideEnforcement = () => {
  log('hide enforcement');
  state.isActive = false;
  restoreFocusState();
  invokeHook(ATTRIBUTE_EVENT_HIDE, new ArkHookEvent(state));
  if (get(state, 'config.accessibilitySettings.lockFocusToModal')) {
    ariaShowSiblings();
  }
  setTimeout(() => {
    render();
    ariaSetAppElHidden(true);
    if (state.completed) resetEnforcement();
  }, ENFORCEMENT_HIDE_TRANSITION_MS * 1.05);
};

const onShowEnforcement = () => {
  log('show enforcement');
  state.isActive = true;
  saveFocusState();
  invokeHook(ATTRIBUTE_EVENT_SHOW, new ArkHookEvent(state));
  if (get(state, 'config.accessibilitySettings.lockFocusToModal')) {
    ariaHideSiblings();
  }
  render();
  ariaSetAppElHidden(false);
};

// eslint-disable-next-line no-unused-vars
const hideEnforcement = () => {
  if (!state.enforcementReady)
    return log('Noop. Enforcement is not ready yet.');
  if (!state.isActive) return log('Noop. Enforcement is already hidden');
  emitter.emit(EMITTER_HIDE_ENFORCEMENT);
};

const showEnforcement = () => {
  if (!state.enforcementReady)
    return log('Noop. Enforcement is not ready yet.');
  if (state.isActive) return log('Noop. Enforcement is already active');
  emitter.emit(EMITTER_SHOW_ENFORCEMENT);
};

const bodyClicked = (event) => {
  const triggerElementClicked = event.target.closest(state.config.selector);

  if (!triggerElementClicked) {
    log(
      `Event target does not match the predefined selector \`${state.config.selector}\``,
      event
    );
    return;
  }

  showEnforcement();
};

const escapePressed = (event) => {
  if (get(event, 'keyCode') !== ESCAPE_KEY_CODE) return null;
  if (!get(state, 'config.settings.lightbox.closeOnEsc', true))
    return log('Noop. close on escape is turned off');
  hideEnforcement();
};

const onEnforcementReady = () => {
  if (state.isResettingChallenge) {
    showEnforcement();
  }
};

const checkInlineActive = () => {
  if (
    get(state, 'config.selector') &&
    document.querySelector(get(state, 'config.selector', '')) &&
    document.querySelector(
      // prettier-ignore
      `${get(state, 'config.selector')} > div > [title="${ENFORCEMENT_FRAME_TITLE}"]`
    )
  ) {
    state.timeoutCheckInlineActive = setTimeout(
      checkInlineActive,
      INLINE_ELEMENT_CHECK_FREQ
    );
    return;
  }

  if (state.completed) return;
  resetEnforcement();
};

const setupInline = () => {
  state.containerEl = document.querySelector(get(state, 'config.selector', ''));

  // prettier-ignore
  if (state.timeoutCheckInlineActive) clearTimeout(state.timeoutCheckInlineActive);
  if (state.timeoutSetupInline) clearTimeout(state.timeoutSetupInline);

  if (state.containerEl) {
    if (!state.containerEl.contains(state.appEl)) {
      state.containerEl.appendChild(state.appEl);
    }
    checkInlineActive();
    return;
  }

  state.timeoutSetupInline = setTimeout(setupInline, INLINE_ELEMENT_CHECK_FREQ);
};

const setupLightbox = (oldState = {}) => {
  const {
    config,
    events,
    appEl,
    enforcementReady,
    bodyElement,
    previousState, // this is already set in setup(), so just copy over
  } = oldState;

  state = getInitialState({ config, events, appEl, enforcementReady });
  state.events.bodyClicked = (...args) => bodyClicked(...args);
  state.events.escapePressed = (...args) => escapePressed(...args);

  if (bodyElement && events) {
    bodyElement.removeEventListener('click', events.bodyClicked);
    window.removeEventListener('keyup', events.escapePressed);
  }
  state.bodyElement.addEventListener('click', state.events.bodyClicked);
  window.addEventListener('keyup', state.events.escapePressed);
  state.previousState = cloneDeep(previousState);

  const element = state.elements()[0];
  const containerSelector = invoke(
    element,
    'getAttribute',
    ATTRIBUTE_CONTAINER_SELECTOR
  );

  state.containerEl = document.querySelector(containerSelector || 'body');
  if (!state.containerEl.contains(state.appEl)) {
    state.containerEl.appendChild(state.appEl);
  }
};

const setupMode = (mode = DEFAULT_MODE) => {
  switch (mode) {
    case INLINE_MODE:
      if (!inlineEmittedReady) {
        inlineEmittedReady = true;

        // inline mode will emit onReady and start watching for target element to create enforcement in
        invokeHook(ATTRIBUTE_EVENT_READY_INLINE, new ArkHookEvent(state));
      }
      setupInline();
      break;
    default:
      setupLightbox(state);
  }
};

const setup = (previousState = {}) => {
  const { config, events, completed } = previousState;

  state = getInitialState({ config, events, completed });
  state.previousState = cloneDeep(previousState);

  setConfig({
    challengeApiUrl: config && config.challengeApiUrl,
    ...config,
  });
};

const configurePublicKey = ({
  challengeApiUrl,
  data,
  language,
  mode,
  selector,
  styleTheme,
  accessibilitySettings = {},
  ...config
} = {}) => {
  state.events = {
    ...state.events,
    [HOOK_ON_COMPLETED]:
      config[HOOK_ON_COMPLETED] || state.events[HOOK_ON_COMPLETED],
    [HOOK_ON_REACH_FAIL_LIMIT]:
      config[HOOK_ON_REACH_FAIL_LIMIT] ||
      state.events[HOOK_ON_REACH_FAIL_LIMIT],
    [HOOK_ON_HIDE]: config[HOOK_ON_HIDE] || state.events[HOOK_ON_HIDE],
    [HOOK_ON_READY]: config[HOOK_ON_READY] || state.events[HOOK_ON_READY],
    [HOOK_ON_RESET]: config[HOOK_ON_RESET] || state.events[HOOK_ON_RESET],
    [HOOK_ON_SHOW]: config[HOOK_ON_SHOW] || state.events[HOOK_ON_SHOW],
    [HOOK_ON_SHOWN]: config[HOOK_ON_SHOWN] || state.events[HOOK_ON_SHOWN],
    [HOOK_ON_SUPPRESS]:
      config[HOOK_ON_SUPPRESS] || state.events[HOOK_ON_SUPPRESS],
  };

  const theme = styleTheme || state.config.styleTheme;
  setConfig({
    challengeApiUrl: challengeApiUrl || CHALLENGE_API_URL,
    clientData: data || state.config.clientData,
    mode: mode || state.config.mode,
    selector: selector || state.config.selector,
    styleTheme: theme,
    siteData: {
      location: window.location,
    },
    settings: {
      language: language || state.config.settings.language,
    },
    accessibilitySettings,
  });

  setupMode(mode);
};

emitter.on(EMITTER_GAME_NUMBER_LIMIT_REACHED, onReachFailLimit);
emitter.on(EMITTER_CHALLENGE_COMPLETED, onChallengeCompleted);
emitter.on(EMITTER_CHALLENGE_FRAME_READY, onChallengeLoaded);
emitter.on(EMITTER_CHALLENGE_LOADED, onChallengeLoaded);
emitter.on(EMITTER_CHALLENGE_SUPPRESSED, onChallengeSuppressed);
emitter.on(EMITTER_CHALLENGE_SHOWN, onChallengeShown);
emitter.on(EMITTER_CONFIG_REQUEST, setConfig);
emitter.on(EMITTER_ENFORCEMENT_LOADED, onEnforcementLoaded);
emitter.on(EMITTER_HIDE_ENFORCEMENT, onHideEnforcement);
emitter.on(EMITTER_SHOW_ENFORCEMENT, onShowEnforcement);
emitter.on(EMITTER_CHALLENGE_RESET, onResetChallenge);
emitter.on(EMITTER_ENFORCEMENT_READY, onEnforcementReady);

const findScriptTag = (list, key) => {
  let element;
  // for is faster than foreach here and allows an early break
  for (let i = 0; i < list.length; i += 1) {
    const check = list[i];
    if (
      String(check.getAttribute('src')).match(key) &&
      check.hasAttribute('data-callback')
    ) {
      element = check;
      break;
    }
  }
  return element;
};

const scriptTag = findScriptTag(
  document.querySelectorAll(API_SCRIPT_TAG_SELECTOR),
  PUBLIC_KEY
);
const callback = scriptTag.getAttribute('data-callback');
const attemptToInvokeCallback = () => {
  if (!isFunction(window[callback])) {
    log(`Invoking callback window.${callback}() failed. Will retry.`);
    return setTimeout(attemptToInvokeCallback, 1000);
  }
  setup();
  window[callback]({
    setConfig: configurePublicKey,
    getConfig,
    reset: resetEnforcement,
    run: showEnforcement,
    version: PACKAGE_VERSION,
  });
};

if (callback) {
  attemptToInvokeCallback(callback);
} else {
  setup();
  setupLightbox(); // default to lightbox
}
