import get from 'lodash/get';
import invoke from 'lodash/invoke';
import { isFunction } from '@/utils/helpers';
import debug from '@/utils/debug/';

const log = debug('utils:getHookFnFromElement');

const getHookFnFromElement = (element, hookName) => {
  const fnName = invoke(element, 'getAttribute', hookName);
  const fn = get(window, fnName);
  return isFunction(fn)
    ? fn
    : () =>
        log(
          `The function name \`${fnName}\` for the hook \`${hookName}\` was not found`
        );
};

export default getHookFnFromElement;
