export const size = (item) =>
  item.constructor === Object ? Object.keys(item).length : item.length;

export const intersection = (arrayCheck, ...args) => {
  const inter = arrayCheck.filter((item) =>
    args.every((arr) => arr.indexOf(item) > -1)
  );
  // dedupes the array, same as lodash intersection
  return inter.filter((item, index, ar) => ar.indexOf(item) === index);
};

export const isFunction = (value) => typeof value === 'function';

// This function shouldn't be called directly, `entries` should be used
// instead, this is just being exported for ease of testing
export const entriesPolyfill = (obj) => {
  const props = Object.keys(obj);
  const { length } = props;
  const returnEntries = new Array(length);
  for (let i = 0; i < length; i += 1) {
    returnEntries[i] = [props[i], obj[props[i]]];
  }
  return returnEntries;
};

export const entries = (obj) =>
  Object.entries ? Object.entries(obj) : entriesPolyfill(obj);
