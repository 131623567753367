import EventEmitter from 'eventemitter3';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { isFunction } from '@/utils/helpers';
import debug from '@/utils/debug/';
import { PUBLIC_KEY } from '@/constants';

const noop = () => {};
const log = debug('utils:emitter');
const stringifyData = (data) => JSON.stringify(data);
const parseData = (data) => JSON.parse(data);

class Emitter {
  constructor() {
    this.config = {
      context: null,
      target: '*',
    };

    this.emitter = new EventEmitter();
    this.log = noop;
    this.logEmit = noop;
    this.logOnWindowMessage = noop;

    window.addEventListener('message', (event = {}) => {
      try {
        const { data, key, message, type } = parseData(event.data) || {};

        if (message && key === PUBLIC_KEY) {
          this.logOnWindowMessage(message, data);
          this.emitter.emit(message, data);
          if (type === 'broadcast')
            this.postMessageToParent({ data, key, message });
          if (type === 'emit')
            this.postMessageToChildren({ data, key, message });
        }
      } catch (err) {
        log('error parsing window message', err);
      }
    });
  }

  set context(context) {
    const logPath = `utils:emitter:${context}`;

    this.config.context = context;
    this.log = debug(logPath);
    this.logBroadcast = debug(`${logPath}:broadcast`);
    this.logEmit = debug(`${logPath}:emit`);
    this.logOnWindowMessage = debug(`${logPath}:onWindowMessage`);
  }

  postMessage(contentWindow = {}, { data, key, message, type }) {
    const isContentWindow = isFunction(contentWindow.postMessage);
    if (isContentWindow) {
      const payload = {
        ...data,
        data,
        key,
        message,
        type,
      };
      log(contentWindow, payload);
      contentWindow.postMessage(stringifyData(payload), this.config.target);
    }
  }

  postMessageToChildren({ data, key, message }) {
    const type = 'emit';
    const children = map(
      document.querySelectorAll('iframe'),
      ({ contentWindow } = {}) => contentWindow
    );

    forEach(children, (contentWindow) => {
      this.postMessage(
        contentWindow,
        {
          data,
          key,
          message,
          type,
        },
        this.config.target
      );
    });
  }

  postMessageToParent({ data, key, message }) {
    const type = 'broadcast';
    const hasParent = window.parent !== window;
    if (hasParent) {
      this.postMessage(window.parent, {
        data,
        key,
        message,
        type,
      });
    }
  }

  emit(message, data) {
    this.logEmit(message, data);

    this.emitter.emit(message, data);
    this.postMessageToParent({ message, data, key: PUBLIC_KEY });
    this.postMessageToChildren({ message, data, key: PUBLIC_KEY });
  }

  off(...args) {
    this.emitter.removeListener(...args);
  }

  on(...args) {
    this.emitter.on(...args);
  }

  once(...args) {
    this.emitter.once(...args);
  }
}

export default new Emitter();
