/* eslint-disable prefer-destructuring */
export const NAME = 'arkose';
export const PUBLIC_KEY = process.env.PUBLIC_KEY;
export const API_SCRIPT_TAG_SELECTOR = 'script';
export const ATTRIBUTE_CHALLENGE_API_URL = `data-${NAME}-challenge-api-url`;
export const ATTRIBUTE_CONTAINER_SELECTOR = `data-${NAME}-container`;
export const ATTRIBUTE_EVENT_LIMIT_REACHED = `data-${NAME}-event-blocked`;
export const ATTRIBUTE_EVENT_COMPLETED = `data-${NAME}-event-completed`;
export const ATTRIBUTE_EVENT_HIDE = `data-${NAME}-event-hide`;
export const ATTRIBUTE_EVENT_READY = `data-${NAME}-event-ready`;
export const ATTRIBUTE_EVENT_READY_INLINE = `data-${NAME}-event-ready-inline`;
export const ATTRIBUTE_EVENT_RESET = `data-${NAME}-event-reset`;
export const ATTRIBUTE_EVENT_SHOW = `data-${NAME}-event-show`;
export const ATTRIBUTE_EVENT_SUPPRESS = `data-${NAME}-event-suppress`;
export const ATTRIBUTE_EVENT_SHOWN = `data-${NAME}-event-shown`;
// This is the URL used to hit our EC API servers.
export const CHALLENGE_API_URL = process.env.CHALLENGE_API_URL;
export const DEFAULT_MODE = 'lightbox';
export const DEFAULT_THEME = 'default';
export const EC_ESCAPE_KEY_MESSAGE = 'key_pressed_27';
export const EVENT_JS_READY = 'js_ready';
export const EVENT_STYLE_LOADED = 'style loaded';
// This is the URL to use for the "Enforcement" iframe src
export const ENFORCEMENT_URL = process.env.ENFORCEMENT_URL;
export const EMITTER_ANALYTICS = 'analytics';
export const EMITTER_CHALLENGE_COMPLETED = 'challenge completed';
export const EMITTER_CHALLENGE_FRAME_READY = 'challenge frame ready';
export const EMITTER_CHALLENGE_LOADED = 'challenge loaded';
export const EMITTER_CHALLENGE_READY = 'challenge ready';
export const EMITTER_CHALLENGE_SHOWN = 'challenge shown';
export const EMITTER_CHALLENGE_IFRAME = 'challenge iframe';
export const EMITTER_CHALLENGE_TRANSITION_IN_COMPLETE =
  'challenge iframe transition in complete';
export const EMITTER_CHALLENGE_SUPPRESSED = 'challenge suppressed';
export const EMITTER_CHALLENGE_RESET = 'challenge reset';
export const EMITTER_COMPLETED_WITHOUT_BEING_CHALLENGED =
  'completed without being challenged';
export const EMITTER_GAME_NUMBER_LIMIT_REACHED =
  'challenge fail number limit reached';
export const EMITTER_CONFIG = 'config';
export const EMITTER_CONFIG_REQUEST = 'request config';
export const EMITTER_ENFORCEMENT_LOADED = 'enforcement loaded';
export const EMITTER_ENFORCEMENT_READY = 'enforcement ready';
export const EMITTER_HIDE_CHALLENGE = 'hide challenge';
export const EMITTER_HIDE_ENFORCEMENT = 'hide enforcement';
export const EMITTER_SHOW_ENFORCEMENT = 'show enforcement';
export const EMITTER_RESET_FOCUS = 'reset_focus';
export const EMITTER_STYLE_THEME = 'style theme';
export const ENFORCEMENT_HIDE_TRANSITION_MS = 400;
export const ESCAPE_KEY_CODE = 27;
export const EVENT_TYPE_LIGHTBOX_DISMISSED = 'user dismissed lightbox';
export const EVENT_TYPE_LIGHTBOX_OPENED = 'user clicked lightbox trigger';
export const EVENT_USER_PRESSED_ESCAPE_KEY = 'user pressed escape key';
export const INLINE_ELEMENT_CHECK_FREQ = 500;
export const INLINE_MODE = 'inline';
export const NODE_ENV = process.env.NODE_ENV;
export const TARGET_ELEMENT_QUERY_SELECTOR = `[data-${NAME}-public-key="${PUBLIC_KEY}"]`;
export const BOOTSTRAP_VERIFY_CLICKED = 'bootstrap_verify_click'; // php-app/fc/gc/bootstrap/funcaptcha_bootstrap.js
export const PACKAGE_VERSION = process.env.PACKAGE_VERSION;
export const ENFORCEMENT_FRAME_TITLE = 'Verification challenge';
export const CHALLENGE_ELEMENT_ID = 'challenge';
export const CHALLENGE_ELEMENT_DATA_E2E = 'challenge-frame';
export const CHALLENGE_ELEMENT_TITLE = 'challenge frame';
export const CHALLENGE_HIDE_TIMEOUT_MS = 2000;
